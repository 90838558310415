* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #05293C;
}

.gray {
  color: #686c74;
}

.purpul {
  color: #cf119e;
}

.green {
  color: #536d1f;
}

.br-10 {
  border-radius: 10px !important;
}

.br-15 {
  border-radius: 15px !important;
}

.fs-10 {
  font-size: 10px !important;
  margin-bottom: 0 !important;
}

.fs-11 {
  font-size: 11px !important;
  margin-bottom: 0 !important;
}

.fs-12 {
  font-size: 12px !important;
  margin-bottom: 0 !important;
}

.fs-13 {
  font-size: 13px !important;
  margin-bottom: 0 !important;
}

.fs-14 {
  font-size: 14px !important;
  margin-bottom: 0 !important;
}

.fs-16 {
  font-size: 16px !important;
  margin-bottom: 0 !important;
}

.fw-500 {
  font-weight: 500;
}

.lh-initial {
  line-height: initial;
}

/* ---------signup--------- */

.signup-icons {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
}

::placeholder {
  color: gray;
  font-size: 13px;

}

input:focus {
  outline: none;
}

.signupInputs {
  width: 100%;
  border: 1px solid gray;
  border-radius: 7px;
  height: 40px;
  padding-left: 10px;
  font-size: 13px;
  color: #333333;
  font-weight: 500;
}

.green-btn {
  height: 40px;
  text-align: center;
  color: white;
  background: #d3d103;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.white-bg-rounded {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 15px;
  border: 0;
  height: fit-content;
}

.cursor-pointer {
  cursor: pointer;
}

.uploadPhoto {
  opacity: 0;
  z-index: 12;
}

.border-dashed-1px {
  border: 1px solid gray;
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 10px;
}

.imagesPreview {
  height: 300px;
  width: 100%;
  /* object-fit: contain; */
}

.index-inoutField {
  width: 100%;
  height: 30px;
  border-radius: 4px;
  padding-left: 10px;
  background: transparent;
  color: white;
}

/* .index-inoutField::placeholder{
  color: white;
} */
.text-danger {
  color: red;
}

.removeArrow::after {
  display: none;
}

.fv-plugins-message-container {
  color: rgb(15, 160, 191);
  ;
}

.infinite-scroll-component {
  overflow: initial !important;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .imagesPreview {
    height: 200px;
    width: 100%;
    /* object-fit: contain; */
  }

  .profile-sm {
    display: block;
  }
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

.dropdown-toggle::after {
  display: none;
}

.login-bg {
  background-image: url('./assets/login-bg.jpg');
  background-size: cover;
  height: 100vh;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .login-bg {
    background-image: url('./assets/mob-bg.jpg');
    background-size: 100% 100%;
    background-position-y: -154px;
    background-repeat: no-repeat;
  }

  .sm-bg-login {
    background: black;
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

.bounce {
  animation: bounce 1s infinite;
}

/* For WebKit based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
  /* Set the width of the scrollbar */
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  /* Set the background color of the scrollbar track */
  border-radius: 50px;

}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Set the color of the scrollbar thumb */
  border-radius: 50px;

}

scrollbar {
  width: 6px;
  background-color: #f1f1f1;
}

/* Set the color of the scrollbar thumb */
scrollbar-thumb {
  background-color: #888;
}

.recharts-legend-wrapper,
.recharts-wrapper,
.recharts-surface {
  width: 100% !important;
}